import config from "../config";
import axios from "axios";
import {
  CreateNewJobsResponse,
  Job,
  JobData,
  JobFilterOptions,
  JobWithData,
  QueryParams,
  TranslationData,
} from "@sumit-platforms/types";

const jobsEndpoint = `${config.server.host}/${config.server.jobs}`;

const JobService = () => {
  const getUserClientJobs = async (
    query?: QueryParams<JobFilterOptions>
  ): Promise<{ jobs: Job[]; totalJobs: number }> => {
    try {
      const jobs = await axios.get<{ jobs: Job[]; totalJobs: number }>(
        `${jobsEndpoint}/getUserClientJobs`,
        {
          params: query,
        }
      );
      return jobs.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getJob = async (
    idJob: number,
    includeJobData = true
  ): Promise<Job | JobWithData> => {
    const jobEndpoint = `${jobsEndpoint}/${idJob}`;
    try {
      const job = await axios.get<Job>(jobEndpoint, {
        params: {
          includeJobData,
        },
      });
      return job.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const create = async (
    uploadIds: number[],
    onUploadProgress?: (data: any) => void
  ): Promise<CreateNewJobsResponse> => {
    try {
      const newJobs = await axios.post<CreateNewJobsResponse>(jobsEndpoint, {
        uploadIds,
      });
      return newJobs.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const update = async (jobIds: number[], updatedJobData: Partial<Job>) => {
    try {
      const updatedJob = await axios.put<Job[]>(jobsEndpoint, {
        idJob: jobIds,
        updatedJobData,
      });
      return updatedJob.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const saveJobData = async (idJob: number, jobData: JobData) => {
    try {
      await axios.post(`${jobsEndpoint}/${idJob}/saveJobData`, jobData);

      return { success: true };
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const setTags = async (idJob: number[], idTag: number[]): Promise<void> => {
    try {
      await axios.put(jobsEndpoint + "/setTags", {
        idJob,
        idTag,
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const deleteJob = async (jobIds: number[]) => {
    try {
      const deletedJob = axios.delete(jobsEndpoint, {
        params: {
          idJob: jobIds,
        },
      });
      return deletedJob;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getJobTypes = async () => {
    try {
      const response = await axios.get(jobsEndpoint + "/jobTypes");
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const approveAutoTranscript = async (idJob: number) => {
    try {
      const response = await axios.get(
        `${jobsEndpoint}/${idJob}/approveAutoTranscript`
      );
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const orderFullTranscript = async (idJob: number) => {
    try {
      const response = await axios.get(
        `${jobsEndpoint}/${idJob}/orderFullTranscript`
      );
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getJobQueryMetaData = async (query?: QueryParams<JobFilterOptions>) => {
    try {
      const jobQueryMetaData = await axios.get(
        `${jobsEndpoint}/jobQueryMetaData`,
        {
          params: query,
        }
      );
      return jobQueryMetaData.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const createJobTranslationFromJob = async ({
    idJobs,
    translationData,
  }: {
    idJobs: number[];
    translationData: TranslationData[];
  }) => {
    try {
      const response = await axios.post<Job[]>(
        `${jobsEndpoint}/createJobTranslationFromJob`,
        {
          idJobs,
          translationData,
        }
      );
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(`Error in createJobTranslationFromJob, with error: ${err}`);
      throw err;
    }
  };

  return {
    getUserClientJobs,
    getJob,
    getJobTypes,
    create,
    update,
    saveJobData,
    setTags,
    deleteJob,
    approveAutoTranscript,
    orderFullTranscript,
    getJobQueryMetaData,
    createJobTranslationFromJob,
  };
};

export default JobService();
