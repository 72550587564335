import config from "../config";
import axios from "axios";
import { Tag } from "@sumit-platforms/types";

const endpoint = `${config.server.host}/${config.server.tags}`;

const TagService = () => {
  const search = async (term: string, entity: string): Promise<Tag[]> => {
    try {
      return (
        await axios.get<Tag[]>(`${endpoint}/search`, {
          params: {
            term,
            entities: [entity],
          },
        })
      ).data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const get = async (entity: string): Promise<Tag[]> => {
    try {
      return (
        await axios.get<Tag[]>(`${endpoint}`, {
          params: {
            entities: [entity],
          },
        })
      ).data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const create = async (
    data: Partial<Tag>,
    idClient: number,
    onTagProgress?: (data: any) => void
  ): Promise<Tag> => {
    try {
      const response = await axios.post(endpoint, { ...data, idClient });
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    search,
    create,
    get,
  };
};

export default TagService();
