import { JobRevisionsRequestParams, JobWithData } from "@sumit-platforms/types";
import { useEffect, useRef, useState } from "react";
import JobService from "../services/jobService";
import { defaultJobRevisionsQuery } from "../constants/default-queries";

interface JobSettings {
  subtitlesValidation: any | Record<any, any>;
  peaks: any | null;
}

export const useJob = ({
  idJob,
  filename = "data.json",
  config,
}: {
  idJob: number;
  filename?: string;
  config: any;
}) => {
  const jobService = JobService({ config });
  const [job, setJob] = useState<JobWithData | null>(null);
  const jobSettings = useRef<JobSettings>({
    subtitlesValidation: {},
    peaks: null,
  }).current;

  const init = async () => {
    const getSubtitlesValidationPromise = getSubtitlesValidation();
    const getJobPeaksPromise = getJobPeaks();
    const getJobPromise = getJob();

    await Promise.all([
      getSubtitlesValidationPromise,
      getJobPeaksPromise,
      getJobPromise,
    ]);
  };

  const getJob = async () => {
    if (!idJob) return;
    try {
      const newJob = await jobService.getJob(idJob);
      setJob(newJob as JobWithData);
      return newJob;
    } catch (e: any) {
      console.error(`Fail to load job data for job ${idJob}`);
      throw new Error(e);
    }
  };

  const getJobRevisions = async (query?: JobRevisionsRequestParams) => {
    if (!idJob) return;
    const revision = await jobService.getJobRevisions({
      excludeSaveTriggers:
        query?.excludeSaveTriggers ||
        defaultJobRevisionsQuery.excludeSaveTriggers,
      idJob,
    });
    return revision;
  };
  const getJobPeaks = async () => {
    const peaks = await jobService.getJobPeaks(idJob);
    jobSettings.peaks = peaks.peaks;
  };

  const getSubtitlesValidation = async () => {
    const subtitlesValidation = await jobService.getSubtitlesValidationSettings(
      idJob
    );
    jobSettings.subtitlesValidation = subtitlesValidation;
  };

  useEffect(() => {
    if (job) return;
    init();
  }, [job]);

  return {
    job,
    getJob,
    setJob,
    getJobPeaks,
    getJobRevisions,
    jobSettings,
  };
};
