import config from "../config";
import axios from "axios";

const searchEndpoint = `${config.server.host}/${config.server.search}`;

export const searchJobContext = async (query: string): Promise<number[]> => {
  try {
    const searchJobContextEndpoint = `${searchEndpoint}/jobContext`;
    const searchResult = await axios.get(searchJobContextEndpoint, {
      params: {
        query,
      },
    });
    return searchResult.data;
  } catch (err) {
    console.error("Failed to search context", err);
    throw err;
  }
};

export const queryExtractIndex = async ({
  query,
  filters,
  page,
  size,
  idClient,
}: {
  query: string;
  filters: any;
  page: number;
  size: number;
  idClient: number;
}): Promise<any[]> => {
  try {
    const queryExtractIndexEndpoint = `${searchEndpoint}/extract`;
    const searchResult = await axios.get(queryExtractIndexEndpoint, {
      params: {
        query,
        filters,
        page,
        size,
        idClient,
      },
    });
    return searchResult.data;
  } catch (err) {
    console.error("Failed to query extract", err);
    throw err;
  }
};
