import React, { FC, useState } from "react";
import authService from "../../services/authService";
import { SignUpForm } from "@sumit-platforms/ui-bazar";

import "./Auth.scss";

const SignUpPage: FC = (): JSX.Element => {
  return (
    <div className="SignUpPage">
      <SignUpForm authService={authService} />
    </div>
  );
};

export default SignUpPage;
