import config from "../../config";
import React, { useEffect, useMemo, useRef } from "react";
import { addDays, format } from "date-fns";
import {
  Button,
  ConfirmModal,
  ExportJobModal,
  CustomModalButton,
  SummarizeJobModal,
} from "@sumit-platforms/ui-bazar";

import {
  useFeatureFlag,
  useJob,
  useNavigateOutPrompt,
  useToast,
} from "@sumit-platforms/ui-bazar/hooks";
import { useAlert, useModal } from "@sumit-platforms/ui-bazar/store";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { Grid } from "@mui/material";
import {
  faFileExport,
  faRectangleList,
  faTruckFast,
} from "@fortawesome/pro-light-svg-icons";
import Editor, {
  ContentEditableEditor,
  EditorImperativeRef,
} from "@sumit-platforms/editor";

import {
  JobService,
  exportService as ExportService,
  UserService,
} from "@sumit-platforms/ui-bazar/services";

import {
  JobAutoTranscriptDeliveryOptions,
  JobWithData,
  jobTypesOptions,
  ExportOptions,
  SaveTriggers,
  UserSettings,
  User,
  Platform,
} from "@sumit-platforms/types";
import {
  getJobTypesOption,
  getTcOffsetByStartTime,
  isJobReadyForSummarizeClients,
} from "@sumit-platforms/ui-bazar/utils";

import { useGlobalData, useNavigationBlocker, useUser } from "../../store";
import { useShallow } from "zustand/react/shallow";

import "./Job.scss";

const jobService = JobService({ config });
const userService = UserService({ config });
const exportService = ExportService({
  config,
});

const Job = () => {
  //GLOBAL STATE
  const { setModalContent, clearModalContent, setModalType } = useModal();
  const { jobTypes, setToast } = useGlobalData(
    useShallow((state) => ({
      jobTypes: state.jobTypes,
      setToast: state.setToast,
    }))
  );
  const { toastInfo, toastSuccess, toastError } = useToast({ setToast });
  const { setBlockNavModal, blockNavModal, clearBlockNavModal } =
    useNavigationBlocker();
  const { user, setUser } = useUser();

  const unmountNavigationBlocker = useNavigateOutPrompt({
    useModal,
    onBeforeConfirm: async () => {
      await onBeforeNavigateOutConfirm();
    },
  });
  const navigate = useNavigate();
  const { idJob = null } = useParams();
  const { job, setJob, jobSettings } = useJob({ config, idJob: Number(idJob) });
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const experimentalSubtitlesEditor = useFeatureFlag(
    "must_experimentalSubtitlesEditor"
  );
  const findAndReplace = useFeatureFlag("must_findAndReplace");
  const timecodePickerFF = useFeatureFlag("timecodePicker");
  const useNewKeyboardShortcuts = useFeatureFlag("must_newKeyboardShortcuts");
  const contentEditableFF =
    useFeatureFlag("must_contentEditableEditor") ||
    ["protocol", "interview", "brief"].includes(job?.type?.typeName || "");
  const karaokeFF = useFeatureFlag("must_karaoke");
  const virtualizeSpeakersFF = useFeatureFlag("must_virtualizedSpeakers");

  const editorRef = useRef<EditorImperativeRef>(null);
  const mode = useMemo(
    () => getJobTypesOption(job, Platform.MUST)?.editorMode,
    [job]
  );

  //TODO: Fix that use effect. navigation is broken
  // useEffect(() => {
  //   if (editorRef?.current?.saveJob) {
  //     if (!blockNavModal) {
  //       setBlockNavModal(handleNavigateOut);
  //     }
  //   }
  // }, [editorRef, handleNavigateOut]);

  useEffect(() => {
    return () => {
      clearBlockNavModal();
    };
  }, [clearBlockNavModal]);

  const saveJob = async (job: JobWithData, saveMethod: SaveTriggers) => {
    if (!idJob) return;
    try {
      setAlert({ message: t("saving"), severity: "info" });
      await jobService.saveJobData({
        idJob: Number(idJob),
        jobData: job.data,
        isClientRevision: true,
        tcOffsets: job.tcOffsets,
        saveTrigger: saveMethod,
      });
      setAlert({ message: t("save_success") });
    } catch (err) {
      setAlert({ message: t("save_fail"), severity: "error" });
    }
  };

  const orderFullTranscript = async () => {
    if (!idJob) return;
    setAlert({ message: t("order_transcription_info"), severity: "info" });
    await jobService.orderFullTranscript(Number(idJob));
    setAlert({
      message: t("order_transcription_success"),
      severity: "success",
    });
  };

  const updateUserSettings = async (newUserSettings: Partial<UserSettings>) => {
    try {
      const updatedSettings = await userService.updateUserSettings(
        newUserSettings
      );
      setUser({ ...(user as User), settings: updatedSettings });
      toastSuccess(t("update_user_settings_success"));
    } catch (e) {
      console.log(
        "Failed to update user settings. User settings will not be updated."
      );
      toastError(t("update_user_settings_failed"));
    }
  };

  const handleOrderFullTranscript = async () => {
    try {
      if (!job) return;
      openConfirmModal({
        title: t("order_transcription_title"),
        message: t("order_transcription_msg", {
          jobType: job.type.typeName,
          deliveryDate: format(
            addDays(new Date(), config.delivery.minDeliveryDays),
            "dd/MM/yyyy"
          ),
        }),
        confirm: async () => {
          await orderFullTranscript();
          navigate("/");
        },
        closeAfterConfirm: true,
      });
    } catch (err) {
      setAlert({ message: "order_full_transcription_fail", severity: "error" });
    }
  };

  const closeModal = (): void => {
    setModalType("info");
    clearModalContent();
  };

  const openConfirmModal = ({
    title,
    message,
    confirm,
    closeAfterConfirm = true,
    customButtons,
  }: {
    title: string;
    message: string;
    confirm: () => void;
    closeAfterConfirm: boolean;
    customButtons?: CustomModalButton[];
  }): void => {
    setModalType("info");
    setModalContent(
      <ConfirmModal
        title={title}
        message={message}
        confirm={confirm}
        cancel={closeModal}
        closeAfterConfirm={closeAfterConfirm}
        customButtons={customButtons || []}
      />
    );
  };

  function handleNavigateOut({ to }: { to: string }) {
    if (editorRef?.current?.isChangesSaved) {
      return unmountNavigationBlocker({ to });
    } else {
      navigate(to);
    }
  }

  async function onBeforeNavigateOutConfirm() {
    if (editorRef.current?.saveJob) {
      await editorRef.current?.saveJob();
    }
  }

  const handleExportJob = async (
    exportArgs: ExportOptions & {
      jobIds: number[];
      fileName: string;
    }
  ) => {
    try {
      await exportService.exportJob(exportArgs);
    } catch (err) {
      toastError(t("export_failed"));
    } finally {
      closeModal();
    }
  };

  const handleOpenExportModal = () => {
    if (!job) return;

    setModalContent(
      <ExportJobModal
        jobTypes={jobTypes}
        jobType={job.type.typeName}
        jobName={job.name}
        templates={job.client?.templates}
        confirm={async (fileName, options) =>
          await handleExportJob({
            jobIds: [job.idJob],
            fileName,
            format: options.format,
            selectedRanges: options.selectedRanges,
            showSpeakers: options.showSpeakers,
            interval: options.interval,
            flip: options.flip,
            tc: options.tc,
            idDocxTemplate: options.idDocxTemplate,
            encoding: options.encoding,
            docxDefaultSettings: options.docxDefaultSettings,
            zeroSubtitle: options.zeroSubtitle,
            emptySubtitle: options.emptySubtitle,
            autoBreak: options.autoBreak,
          })
        }
        selectedJobs={[job]}
        selectedRanges={editorRef.current?.selectedRanges}
        tcOffset={getTcOffsetByStartTime(job.tcOffsets)}
        cancel={closeModal}
        disabled={false}
      />
    );
  };

  const handleSummarizeJob = async (idJob: number, fileName: string) => {
    try {
      await exportService.createJobSummary({ idJob, fileName });
    } catch (err) {
      toastError(t("export_brief_failed"));
    } finally {
      closeModal();
    }
  };

  const openSummarizeJobModal = (idJob: number, jobName: string) => {
    setModalContent(
      <SummarizeJobModal
        idJob={idJob}
        jobName={jobName}
        onApprove={handleSummarizeJob}
        onCancel={closeModal}
      />
    );
  };

  return !job || !job.data || !job.media ? (
    <div>Loading Job...</div>
  ) : (
    <div className="Job">
      <Grid item xs={12} p={2}>
        <Button
          variant={"outlined"}
          onClick={() => handleNavigateOut({ to: "/" })}
        >
          {t("back_to_deliveries")}
        </Button>
      </Grid>
      {contentEditableFF && (mode === "transcript" || mode === "subtitles") ? (
        <ContentEditableEditor
          job={job}
          jobSettings={jobSettings}
          setJob={(job: JobWithData) => setJob(job)}
          save={saveJob}
          showSubtitles={jobTypesOptions[
            job.type.typeName || "subtitles"
          ].editorMode.includes("subtitles")}
          mode={mode}
          toast={setAlert}
          useModal={useModal}
          jobActions={[
            {
              key: "brief",
              label: t("export_brief"),
              icon: faRectangleList,
              onClick: () => openSummarizeJobModal(job.idJob, job.name),
              fullButton: true,
              hide: !isJobReadyForSummarizeClients(job),
            },
            {
              key: "export",
              label: t("export"),
              icon: faFileExport,
              fullButton:
                job.autoTranscriptDelivery ===
                JobAutoTranscriptDeliveryOptions.delivered,
              onClick: handleOpenExportModal,
            },
            {
              key: "order",
              label: t("order_transcription_button"),
              icon: faTruckFast,
              onClick: handleOrderFullTranscript,
              fullButton: true,
              hide:
                job.autoTranscriptDelivery !==
                JobAutoTranscriptDeliveryOptions.delivered,
            },
          ]}
          ref={editorRef}
          featureFlags={{
            experimentalSubtitlesEditor,
            timecodePicker: timecodePickerFF,
            useNewKeyboardShortcuts,
            contentEditable: contentEditableFF,
            findAndReplace,
            karaoke: karaokeFF,
            virtualizeSpeakers: virtualizeSpeakersFF,
          }}
          userSettings={user?.settings}
          updateUserSettings={updateUserSettings}
          startAsReadOnly={true}
        />
      ) : (
        <Grid container className="Job Page" px={2}>
          <Grid container justifyContent={"center"} py={2}>
            <Grid item xs={12} className="editorSection">
              <Editor
                job={job}
                jobSettings={jobSettings}
                setJob={(job: JobWithData) => setJob(job)}
                save={saveJob}
                showSubtitles={jobTypesOptions[
                  job.type.typeName || "subtitles"
                ].editorMode.includes("subtitles")}
                mode={mode}
                toast={setAlert}
                useModal={useModal}
                jobActions={[
                  {
                    key: "export",
                    label: t("export"),
                    icon: faFileExport,
                    fullButton:
                      job.autoTranscriptDelivery ===
                      JobAutoTranscriptDeliveryOptions.delivered,
                    onClick: handleOpenExportModal,
                  },
                  {
                    key: "order",
                    label: t("order_transcription_button"),
                    icon: faTruckFast,
                    onClick: handleOrderFullTranscript,
                    fullButton: true,
                    hide:
                      job.autoTranscriptDelivery !==
                      JobAutoTranscriptDeliveryOptions.delivered,
                  },
                ]}
                ref={editorRef}
                featureFlags={{
                  experimentalSubtitlesEditor,
                  timecodePicker: timecodePickerFF,
                  useNewKeyboardShortcuts,
                  contentEditable: contentEditableFF,
                }}
                userSettings={user?.settings}
                updateUserSettings={updateUserSettings}
                startAsReadOnly={true}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Job;
