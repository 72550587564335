import _ from "lodash";
import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSparkles, faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MultiSelect, Option } from "../../core/MultiSelect/MultiSelect";
import { ToggleButtonGroup } from "../../core/ToggleButtonGroup/ToggleButtonGroup";
import Input from "../../core/Input/Input";
import { TODAY_MIDNIGHT } from "../../constants/globals";
import { DateRangeInput } from "../../core/DateRangeInput/DateRangeInput";
import { isValid } from "date-fns";

import {
  ExtractSegmentField,
  FieldOperator,
  TextualIndexType,
  ExtractField,
  Portrait,
} from "@sumit-platforms/types";

import "./ExtractFieldRow.scss";

interface ExtractFieldRowProps {
  tags: string[];
  speakers: string[];
  portraits: Portrait[];
  field: ExtractSegmentField;
  onFieldChange: (field: ExtractSegmentField) => void;
  deleteField: () => void;
  inputFields: ExtractField[];
  disabled?: boolean;
  inputRef?: React.RefObject<any>;
}

export const ExtractFieldRow = ({
  tags,
  speakers,
  portraits,
  field,
  onFieldChange,
  deleteField,
  inputFields,
  disabled,
  inputRef,
}: ExtractFieldRowProps) => {
  const { t } = useTranslation();

  const getDateValues = useCallback(
    ({ index }: { index: number }) => {
      let dateValue = TODAY_MIDNIGHT;
      if (
        _.isArray(field?.value) &&
        !_.isUndefined(field?.value[index]) &&
        isValid(new Date(field.value[index]))
      )
        dateValue = new Date(field.value[index]);
      return dateValue;
    },
    [field]
  );

  const fieldTypesOptions = useMemo<Option[]>(
    () =>
      inputFields.map(
        (inputField) =>
          ({
            value: inputField.inputLabel,
            label: t(`extract_${inputField.inputLabel}`),
            iconElem:
              inputField.inputLabel === "magic_search" ? (
                <FontAwesomeIcon icon={faSparkles}></FontAwesomeIcon>
              ) : undefined,
          } as Option)
      ),
    [inputFields]
  );

  const [fieldOperatorButtons] = useState<
    {
      value: FieldOperator;
      label: string;
    }[]
  >(
    Object.values(FieldOperator).map((sign) => ({
      value: sign,
      label: t(sign),
    }))
  );

  const [textualIndexTypeButtons] = useState<
    {
      value: TextualIndexType;
      label: string;
    }[]
  >(
    Object.values(TextualIndexType).map((textualSearch) => ({
      value: textualSearch,
      label: t(`search_in_${textualSearch}`),
    }))
  );

  const valueInputOptions: Option[] = useMemo(() => {
    if (field.type === "tags") {
      return tags.map((tag) => ({ label: t(tag), value: tag }));
    }
    if (field.type === "speaker") {
      return speakers.map((speaker) => ({ label: t(speaker), value: speaker }));
    }
    if (field.type === "face_recognition") {
      return portraits.map((portrait) => ({
        label: portrait.name,
        value: portrait.portrait,
      }));
    }
    return [];
  }, [field.type]);

  const handleFieldOperatorChange = (
    _e: MouseEvent<HTMLElement>,
    newValue: FieldOperator
  ) => {
    if (!newValue) return;
    onFieldChange({
      ...field,
      operator: newValue,
    });
  };

  const handleFieldTextualSearchChange = (
    _e: MouseEvent<HTMLElement>,
    newValue: TextualIndexType
  ) => {
    if (!newValue) return;
    onFieldChange({
      ...field,
      textualIndexType: newValue,
    });
  };

  const handleFieldTypeChange = useCallback(
    (selected: string[]) => {
      const selectedField = inputFields.find(
        (inputField) => inputField.inputLabel === selected[0]
      );

      const _field: ExtractSegmentField = {
        ...field,
        type: selectedField?.inputLabel as string,
      };

      switch (selectedField?.inputType) {
        case "text":
          if (typeof _field.value !== "string") _field.value = "";
          break;
        case "select":
        case "multi-select":
          _field.value = [];
          break;
        case "date":
          _field.value = [
            TODAY_MIDNIGHT.toISOString(),
            TODAY_MIDNIGHT.toISOString(),
          ];
          break;
        default:
          _field.value = "";
      }
      if (selectedField?.paragraphSearch) {
        _field.textualIndexType = TextualIndexType.PARAGRAPH;
      } else {
        _field.textualIndexType = TextualIndexType.SENTENCE;
      }
      onFieldChange(_field);
    },
    [inputFields, field]
  );

  const handleOnTextChange = (e: Partial<ChangeEvent<HTMLInputElement>>) => {
    onFieldChange({
      ...field,
      value: e.target?.value || "",
    });
  };

  const handleOnDateChange = useCallback(
    ({ index }: { index: number }) => {
      const dateChange = (date: Date) => {
        if (!_.isArray(field.value) || field.value?.length < 2) return;
        const newValue = [...field.value];
        newValue[index] = date.toISOString();
        onFieldChange({
          ...field,
          value: newValue as any,
        });
      };
      return dateChange;
    },
    [field]
  );

  const getExtractFieldByCurrentField = useCallback(
    (field: ExtractSegmentField) => {
      const extractField = inputFields.find(
        (inputField) => inputField.inputLabel === field.type
      );
      return extractField;
    },
    [inputFields]
  );

  const handleOnSelectChange = (selected: string[]) => {
    onFieldChange({
      ...field,
      value: selected,
    });
  };

  return (
    <Box className={"ExtractFieldRow"}>
      <Box className="typeWrapper">
        <MultiSelect
          selected={[field.type]}
          setSelected={handleFieldTypeChange}
          placeholder={t("search_by")}
          options={fieldTypesOptions}
          disabled={disabled}
        />
      </Box>
      <Box className="signOperatorWrapper">
        <ToggleButtonGroup
          buttons={fieldOperatorButtons}
          value={field.operator}
          onChange={handleFieldOperatorChange}
          disabled={disabled}
        />
      </Box>
      <Box className="valueWrapper">
        {getExtractFieldByCurrentField(field)?.inputType === "text" && (
          <Input
            disabled={disabled}
            value={field.value || ""}
            type="text"
            onChange={handleOnTextChange}
            refs={inputRef}
          ></Input>
        )}
        {getExtractFieldByCurrentField(field)?.inputType === "date" && (
          <Box className="uploadedWrapper">
            <DateRangeInput
              valueFrom={getDateValues({ index: 0 })}
              valueTo={getDateValues({ index: 1 })}
              onValueFromChange={handleOnDateChange({ index: 0 })}
              onValueToChange={handleOnDateChange({ index: 1 })}
              disabled={disabled}
            />
          </Box>
        )}
        {(getExtractFieldByCurrentField(field)?.inputType === "multi-select" ||
          getExtractFieldByCurrentField(field)?.inputType === "select") && (
          <MultiSelect
            selected={!_.isArray(field.value) ? [field.value] : field.value}
            setSelected={handleOnSelectChange}
            placeholder={t("search_by")}
            options={valueInputOptions}
            isMulti={
              getExtractFieldByCurrentField(field)?.inputType === "multi-select"
            }
            disabled={disabled}
          />
        )}
      </Box>
      {_.isBoolean(getExtractFieldByCurrentField(field)?.paragraphSearch) && (
        <Box className="searchTypeWrapper">
          <ToggleButtonGroup
            buttons={textualIndexTypeButtons}
            value={field.textualIndexType}
            onChange={handleFieldTextualSearchChange}
            disabled={disabled}
          />
        </Box>
      )}
      <Box className="removeWrapper">
        <Button variant={"text"} disabled={disabled}>
          <FontAwesomeIcon onClick={deleteField} icon={faTimesCircle} />
        </Button>
      </Box>
    </Box>
  );
};
