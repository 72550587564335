import _ from "lodash";
import { useMemo, useState } from "react";
import {
  ProjectTable,
  ProjectTableQuery,
  QueryParams,
} from "@sumit-platforms/types";
import ProjectService from "./../services/projectService";

export const useProjectsTable = ({
  config,
}: {
  config?: any;
}): {
  projects: ProjectTable[];
  getProjects: (query?: QueryParams<ProjectTableQuery>) => Promise<void>;
  projectsQueryMetaData: any;
  getProjectsQueryMetaData: () => Promise<void>;
  totalProjects: number;
  hasMore: boolean;
  isLoading: boolean;
} => {
  const projectService = useMemo(() => ProjectService({ config }), []);
  const [totalProjects, setTotalProjects] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [projectsQueryMetaData, setProjectQueryMetaData] = useState<any>({});
  const [projects, setProjects] = useState<ProjectTable[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getProjectsQueryMetaData = async () => {
    const _projectsQueryMetaData =
      await projectService.getProjectsQueryMetaData();
    setProjectQueryMetaData(_projectsQueryMetaData);
  };

  const getProjects = async (query?: QueryParams<ProjectTableQuery>) => {
    try {
      setIsLoading(true);
      const { projects, totalProjects } = await projectService.getTableProjects(
        query
      );
      setProjects((prev: ProjectTable[]) => {
        if (query && _.isNumber(query?.offset) && query.offset > 0) {
          const aggregatedProjects = [...prev, ...projects];
          setHasMore(aggregatedProjects.length !== totalProjects);
          return aggregatedProjects;
        } else {
          setHasMore(projects.length !== totalProjects);
          return projects;
        }
      });
      setTotalProjects(totalProjects);
      setIsLoading(false);
    } catch (e: any) {
      throw new Error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    projects,
    getProjects,
    projectsQueryMetaData,
    getProjectsQueryMetaData,
    totalProjects,
    hasMore,
    isLoading,
  };
};
