import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import "./Stat.scss";
import { useTranslation } from "react-i18next";

interface IStatProps {
  icon: IconProp;
  type: string;
  total: number;
  totalLastMonth: number;
}
const Stat: FC<IStatProps> = ({ total, icon, type, totalLastMonth }) => {
  const { t } = useTranslation();
  return (
    <div className="statRoot">
      <div className="iconWrapper">
        <FontAwesomeIcon icon={icon} />
      </div>

      <div className="contentWrapper">
        <div className="totalWrapper">
          {total} {type}
        </div>
        <div className="lastMonthWrapper">
          <div className="totalLastMonth">+{totalLastMonth}</div>
          <div className="lastMonthText">{t("last30Days")}</div>
        </div>
      </div>
    </div>
  );
};

export default Stat;
