import _ from "lodash";
import { Client, ExtractField } from "@sumit-platforms/types";
import { useEffect, useState } from "react";

const SUPPORTED_INPUT_TYPES = ["text", "multi-select", "date", "select"];

export const useExtract = ({
  extractService,
  client,
}: {
  extractService: any;
  client?: Client | null;
}) => {
  const [fields, setFields] = useState<ExtractField[] | null>(null);

  const getExtarctFields = async () => {
    try {
      const _fields = await extractService.getFields();
      setFields(
        _fields?.filter((field: ExtractField) =>
          SUPPORTED_INPUT_TYPES.includes(field.inputType)
        )
      );
    } catch (err) {
      setFields([]);
      console.log(
        "An error occured on getExtarctFields within 'useExtract' hook"
      );
    }
  };

  useEffect(() => {
    getExtarctFields();
  }, [client]);

  return { fields, setFields };
};
