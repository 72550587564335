import React, { useMemo } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Button } from "../../core/Button/Button";
import { Modal } from "../../core/Modal/Modal";

import {
  faAt,
  faPhone,
  IconDefinition,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Box, Grid, Link, Typography } from "@mui/material";
import { openMail, openTel, openWhatsapp } from "../../utils/leads";

import "./DisabledClientModal.scss";

export interface DisabledClientProps {
  approve: () => void;
}

export const DisabledClientModal = ({ approve }: DisabledClientProps) => {
  const { t } = useTranslation();

  const details = useMemo(
    () => [
      {
        detail: "+972-508-820814",
        icon: faPhone,
        action: () => {
          openTel("+972508820814");
        },
      },
      {
        detail: "Whatsapp",
        icon: faWhatsapp as IconDefinition,
        action: () => {
          openWhatsapp("+972508820814");
        },
      },
      {
        detail: "support@sumit-ai.com",
        icon: faAt,
        action: () => {
          openMail("support@sumit-ai.com", "Sumit-AI Contact Us");
        },
      },
    ],
    []
  );

  return (
    <Modal closeModal={approve} type={"info"}>
      <Grid
        container
        width={"36rem"}
        maxHeight={"30rem"}
        padding={2}
        display={"flex"}
        justifyContent={"center"}
      >
        <Grid
          container
          display={"flex"}
          direction={"column"}
          alignItems={"center"}
        >
          <Typography sx={{ fontSize: 22 }} pb={1}>
            {t("client_out_of_credit")}
          </Typography>
        </Grid>
        <Grid container display={"flex"} direction={"column"} width={"100%"}>
          <Grid
            container
            width={"100%"}
            px={2}
            justifyContent={"center"}
          ></Grid>
          <Typography sx={{ fontSize: 18 }} pb={1}>
            {t("disabled_contact_us")}
          </Typography>
        </Grid>
        <Grid
          item
          pb={2}
          py={2}
          display={"flex"}
          width={"80%"}
          height={"fit-content"}
          alignContent={"start"}
          flexDirection={"column"}
          marginTop={"auto"}
          justifyContent={"space-evenly"}
        >
          {_.map(details, (d, i) => (
            <Link
              href="a"
              key={i}
              display={"flex"}
              alignItems={"center"}
              gap={"15px"}
              py={"15px"}
              onClick={d.action}
            >
              <FontAwesomeIcon icon={d.icon} />
              <Typography>{d.detail}</Typography>
            </Link>
          ))}
        </Grid>
        <Grid
          container
          display={"flex"}
          direction={"column"}
          width={"100%"}
          alignItems={"center"}
        >
          <Box>
            <Button onClick={approve}>{t("okay")}</Button>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
};
