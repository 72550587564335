import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import authService from "../services/authService";

const Guest = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (authService.auth.currentUser) {
      navigate("/");
    }
  }, [authService?.auth?.currentUser]);
  return <Outlet />;
};

export default Guest;
