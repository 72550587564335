import config from "./config";
import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { I18nProvider } from "./i18n/I18nProvider";
import {
  getFeatureFlagContext,
  ShortcutsProvider,
} from "@sumit-platforms/ui-bazar/hooks";

import "./axios.config";
import B2B from "./B2B";
import "./index.scss";

Sentry.init({
  dsn: config.sentry.dsn,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", "https://must.sumit-ai.com/api"],
    }),
    new Sentry.Replay(),
  ],
  environment: config.environment,
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const FeatureFlagProvider = getFeatureFlagContext({ config });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <I18nProvider>
      <FeatureFlagProvider>
        <ShortcutsProvider>
          <B2B />
        </ShortcutsProvider>
      </FeatureFlagProvider>
    </I18nProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
