import config from "../../config";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import {
  GeneralTable,
  SearchAndFilters,
  ContextOption,
  errorColor,
  UpdateProjectModal,
  DeleteProjectModal,
} from "@sumit-platforms/ui-bazar";

import {
  ProjectTable,
  ProjectTableQuery,
  QueryParams,
} from "@sumit-platforms/types";

import { ProjectService } from "@sumit-platforms/ui-bazar/services";
import {
  useHeadCells,
  useQuery,
  useProjectsTable,
  useToast,
} from "@sumit-platforms/ui-bazar/hooks";
import { clientStore } from "../../store/client";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import { useModal } from "@sumit-platforms/ui-bazar/store";
import {
  PROJECTS_INITIAL_ORDER,
  PROJECTS_INITIAL_ORDER_BY,
  PROJECTS_INITIAL_QUERY_LIMIT,
  PROJECTS_INITIAL_QUERY_OFFSET,
} from "@sumit-platforms/ui-bazar/constants";

import "./Projects.scss";
import { useGlobalData } from "../../store";

interface ProjectRow {
  id: number;
  name: string;
  jobsAmount?: number;
  totalJobsDuration?: number;
  lastActivity?: Date;
}

const projectService = ProjectService({ config });

const Projects = () => {
  const { t } = useTranslation();
  const { setToast } = useGlobalData();
  const { client } = clientStore();
  const [projectRows, setProjectRows] = useState<ProjectRow[]>([]);
  const { setModalContent, clearModalContent, setModalType } = useModal();
  const { toastInfo, toastSuccess, toastError } = useToast({ setToast });

  const defaultQuery: ProjectTableQuery = useMemo<ProjectTableQuery>(() => {
    return {
      idClient: client?.idClient,
    };
  }, [client]);

  const {
    projects,
    getProjects,
    totalProjects,
    hasMore,
    isLoading: isProjectsLoading,
  } = useProjectsTable({ config });

  const {
    onScroll,
    onSearch,
    onFilterChange,
    loading: isQueryLoading,
  } = useQuery<ProjectTableQuery>(
    {
      queryLimit: PROJECTS_INITIAL_QUERY_LIMIT,
      queryOffset: PROJECTS_INITIAL_QUERY_OFFSET,
      order: PROJECTS_INITIAL_ORDER,
      orderBy: PROJECTS_INITIAL_ORDER_BY,
      onBuildQuery,
      onResetOffset: () => {
        setProjectRows([]);
      },
    },
    [client]
  );

  async function onBuildQuery({
    query,
  }: {
    query: QueryParams<ProjectTableQuery>;
  }) {
    if (_.isEmpty(client) || _.isUndefined(client)) return;
    if (!client) return;
    query.filters = { idClient: client.idClient };
    getProjects(query);
  }

  const closeModal = (): void => {
    setModalType("info");
    clearModalContent();
  };

  const handleEditProjectNameModal = async (
    name: string,
    idProject: number
  ) => {
    try {
      const project = await projectService.updateProject(
        {
          name,
        },
        idProject
      );
      const newProjectRows = projectRows.map((r) => {
        if (r.id === idProject) {
          r = createProjectRow(project);
        }
        return r;
      });
      setProjectRows(newProjectRows);
      toastSuccess(t("done"));
    } catch (err) {
      toastError(t("failed"));
      console.error(err);
    }
  };

  const handleAddNewProject = async (name: string, idClient: number) => {
    try {
      const project = await projectService.createNewProject({ name, idClient });
      const newProjectRow = createProjectRow({
        ...project,
        totalJobsDuration: 0,
        jobsCount: 0,
      });
      setProjectRows((prev) => [newProjectRow, ...prev]);
      toastSuccess(t("done"));
    } catch (err) {
      toastError(t("failed"));
      console.error(err);
    }
  };

  const handleRemoveProject = async (
    sourceIdProject: number,
    targetIdProject: number
  ) => {
    try {
      const targetProjectRow = await projectService.removeProject(
        sourceIdProject,
        targetIdProject
      );
      const newProjectRows = projectRows
        .filter((r) => r.id !== sourceIdProject)
        .map((r) => {
          if (r.id === targetProjectRow.idProject) {
            r = createProjectRow(targetProjectRow);
          }
          return r;
        });
      setProjectRows(newProjectRows);
      toastSuccess(t("done"));
    } catch (err) {
      toastError(t("failed"));
      console.error(err);
    }
  };

  const openEditProjectNameModal = (projectRow: ProjectRow) => {
    if (!client?.idClient) return;
    setModalContent(
      <UpdateProjectModal
        modalTitle={t("edit_project_name")}
        name={projectRow.name}
        confirm={async (name: string) => {
          await handleEditProjectNameModal(name, projectRow.id);
          closeModal();
        }}
        cancel={closeModal}
      />
    );
  };

  const openAddNewProjectModal = () => {
    if (!client?.idClient) return;
    setModalContent(
      <UpdateProjectModal
        modalTitle={t("add_new_project")}
        confirm={async (name: string) => {
          await handleAddNewProject(name, client.idClient);
          closeModal();
        }}
        cancel={closeModal}
      />
    );
  };

  const openDeleteProjectModal = useCallback(
    (row: ProjectRow) => {
      if (!client?.idClient) return;
      setModalType("danger");
      setModalContent(
        <DeleteProjectModal
          projects={projectRows.map((r) => ({ idProject: r.id, name: r.name }))}
          confirm={async (sourceIdProject: number, targetIdProject: number) => {
            await handleRemoveProject(sourceIdProject, targetIdProject);
            closeModal();
          }}
          cancel={closeModal}
          sourceIdProject={row.id}
          sourceProjectJobsNumber={row.jobsAmount || 0}
        />
      );
    },
    [projectRows]
  );

  const headCells = useHeadCells({
    headCellsKeys: [
      "projectName",
      "projectVolume",
      "projectLastActivity",
      "contextMenu",
    ],
    tableContextCallBack: (row: ProjectRow) =>
      [
        {
          name: t("edit_project_name"),
          action: () => {
            openEditProjectNameModal(row);
          },
        },
        {
          name: t("delete_project"),
          color: errorColor,
          action: () => {
            openDeleteProjectModal(row);
          },
        },
      ] as ContextOption[],
    cellLink: {
      projectName: (r) => "/projects/" + r.id,
    },
    labelAction: {
      contextMenu: openAddNewProjectModal,
    },
    labelIcon: {
      contextMenu: faPlusCircle,
    },
    styles: {
      contextMenu: {
        width: 0,
      },
      projectName: {
        width: "60%",
      },
      projectVolume: {
        width: "20%",
      },
      projectLastActivity: {
        width: "20%",
      },
    },
  });

  const createProjectRow = (project: ProjectTable): ProjectRow => {
    return {
      id: project.idProject,
      name: project.name,
      jobsAmount: project.jobsCount,
      totalJobsDuration: project.totalJobsDuration,
      lastActivity: project.lastActivity
        ? new Date(project.lastActivity)
        : undefined,
    };
  };

  useEffect(() => {
    if (projects) {
      const projectRows = projects.map(createProjectRow);
      setProjectRows(projectRows);
    }
  }, [projects]);

  return (
    <Grid
      container
      className="Projects Page"
      display={"flex"}
      justifyContent={"center"}
    >
      <Grid item xs={11} mb={3}>
        <h1 className="pageTitle">{t("projects")}</h1>
        <h3 className="pageSubTitle">
          {`${t("showing")} ${projectRows?.length} ${t(
            "from"
          )} ${totalProjects}`}
        </h3>
      </Grid>
      <Grid item xs={11} mb={3}>
        <SearchAndFilters
          isLoading={isQueryLoading}
          direction={t("dir")}
          onSearch={onSearch}
          searchPlaceholder={t("search")}
          filterBtnTitle={t("filters")}
          title={t("filter_by")}
          defaultQuery={defaultQuery}
          onFilterChange={onFilterChange}
        />
      </Grid>
      <Grid item xs={11} mb={3}>
        <GeneralTable
          headCells={headCells.headCells}
          rows={projectRows}
          allowSelect={false}
          loading={isProjectsLoading}
          hasMore={hasMore}
          onLoadMore={onScroll}
        />
      </Grid>
    </Grid>
  );
};

export default Projects;
