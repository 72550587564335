import { Card, CardContent, Typography } from "@mui/material";
import { FC } from "react";
import Stat from "./Stat/Stat";
import { faClock, faFile, faSearch } from "@fortawesome/pro-light-svg-icons";
import "./Stats.scss";
import { useTranslation } from "react-i18next";
interface IStatsProps {
  files: {
    total: number;
    lastMonth: number;
  };
  minutes: {
    total: number;
    lastMonth: number;
  };
  searches: {
    total: number;
    lastMonth: number;
  };
}

const Stats: FC<IStatsProps> = ({ files, minutes, searches }) => {
  const { t } = useTranslation();
  return (
    <Card className="statRoot">
      <CardContent className="cardContent">
        <Typography variant="h6">{t("main_states")}</Typography>
        <Typography variant="body2">{t("your_usage_in_numbers")}</Typography>
        <div className="statsItems">
          <Stat
            icon={faFile}
            type="files"
            total={files.total}
            totalLastMonth={files.lastMonth}
          />

          <Stat
            icon={faClock}
            type="minutes"
            total={minutes.total}
            totalLastMonth={minutes.lastMonth}
          />

          <Stat
            icon={faSearch}
            type="searches"
            total={searches.total}
            totalLastMonth={searches.lastMonth}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default Stats;
