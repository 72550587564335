import config from "../../config";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Grid, Link, Typography } from "@mui/material";
import { useAlert } from "@sumit-platforms/ui-bazar/store";
import {
  Breadcrumbs,
  Button,
  PageSettings,
  SettingsPager,
} from "@sumit-platforms/ui-bazar";
import { ProjectService } from "@sumit-platforms/ui-bazar/services";
import { clientStore } from "../../store/client";
import { Platform, sttPriorityOptions } from "@sumit-platforms/types";
import { ProjectSettingsPagesMetadata } from "@sumit-platforms/ui-bazar/hooks";

import "./ProjectSettingsEditor.scss";

const projectService = ProjectService({ config });

export const ProjectSettingsEditor = ({
  useSettings,
}: {
  useSettings: any;
}) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { client } = clientStore();
  const { idProject } = useParams();
  const navigate = useNavigate();
  const [settings, setSettings] = useState<any>();
  const outputSettings = useRef<any>();
  const [metadata, setMetadata] = useState<any>({});
  const { pages, setPages } = useSettings({
    settings,
    metadata,
    platform: Platform.MUST,
  });
  const [listButtons] = useState<React.ReactNode[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [actions, setActions] = useState<{
    saveSettings: ((idProject: number) => void) | null;
    fetchSettings: ((idProject: number) => void) | null;
  }>({
    saveSettings: null,
    fetchSettings: null,
  });

  const handleSaveSettings = async () => {
    try {
      setLoading(true);
      if (actions.saveSettings) await actions?.saveSettings(Number(idProject));
      setLoading(false);
    } catch (err) {
      setAlert({
        message: t("failed"),
        severity: "error",
      });
      setLoading(false);
    }
  };

  const handleFetchProject = useCallback(async () => {
    try {
      if (idProject) {
        const projectPage = await projectService.clientsGetProjectPage(
          +idProject
        );
        if (projectPage.idClient !== client?.idClient) {
          navigate(`/projects`);
        }
        setSettings(projectPage.settings);
      }
    } catch (err) {
      navigate(`/projects`);
    }
  }, [client, idProject]);

  const setProjectDeps = useCallback(() => {
    setActions({
      saveSettings: async (idProject: number) => {
        if (!client?.idClient) return;
        await projectService.clientUpdateProjectSettings({
          settings: outputSettings.current,
          idProject,
          idClient: client?.idClient,
        });
        navigate(`/projects`);
      },
      fetchSettings: async (idProject: number) => {
        const _metadata: ProjectSettingsPagesMetadata = {
          sttPriority: sttPriorityOptions,
          validationOptions: [],
        };
        if (client && client.validations?.length > 0) {
          const validations = client.validations;
          if (!_.isEmpty(validations)) {
            _metadata.validationOptions = validations.map((v) => ({
              label: v.settings?.general_lang?.presetName,
              value: v.idValidationPreset,
            }));
          }
        }
        setMetadata(_metadata);
        handleFetchProject();
      },
    });
  }, [client]);

  useEffect(() => {
    let combinedSettings: any = {};
    pages.map((p: PageSettings<any>) => {
      combinedSettings = { ...combinedSettings, ...p.settings };
    });
    outputSettings.current = combinedSettings;
  }, [pages]);

  useEffect(() => {
    if (actions.fetchSettings) {
      actions.fetchSettings(Number(idProject));
    }
  }, [actions.fetchSettings, idProject]);

  useEffect(() => {
    setProjectDeps();
  }, [client]);

  return (
    <Grid
      className="ProjectSettingsEditor"
      container
      display={"flex"}
      justifyContent={"center"}
    >
      <Grid item xs={11} my={2}>
        <Breadcrumbs
          crumbs={[
            {
              label: t("projects"),
              path: "/projects",
            },
            { label: idProject + "" },
          ]}
        />
      </Grid>
      <Grid container item xs={11} gap={1}>
        <Grid item md={12} lg={6} className="settingsPagerWrapper">
          <SettingsPager
            pages={pages}
            setPages={setPages}
            listButtons={listButtons}
          />
        </Grid>
        <Grid item md={12} lg={5}>
          <Grid item xs={12}>
            <Box display={"inline-block"}>
              <Button onClick={handleSaveSettings} loading={loading}>
                {t("save")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
