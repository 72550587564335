import _ from "lodash";
import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { Button, MultiSelect } from "@sumit-platforms/ui-bazar";
import { useTranslation } from "react-i18next";
import { useGlobalData } from "./../../../../store";
import { Project } from "@sumit-platforms/types";

import "./EditProjectModal.scss";

export interface EditProjectModalProps {
  idJob: number;
  idProject: number;
  close: () => void;
  save: (idJob: number, project: Project) => Promise<void>;
}

export const EditProjectModal = ({
  idJob,
  idProject,
  close,
  save,
}: EditProjectModalProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSeleccted] = useState([idProject]);
  const { projects } = useGlobalData();

  const handleSave = async () => {
    if (_.isEmpty(selected)) return;
    const project = projects.find((p) => p.idProject === selected[0]);
    if (!project) return;
    setIsLoading(true);
    await save(idJob, project);
    setIsLoading(false);
    close();
  };

  return (
    <Grid
      className={"EditProjectModal"}
      container
      maxWidth={420}
      justifyContent={"center"}
      px={4}
      py={3}
    >
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        gap={2}
        pb={3}
      >
        <span className="modalName">{t("change_job_project")}</span>
      </Grid>
      <Grid item pb={4} xs={12}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
          rowGap={2}
        >
          <MultiSelect
            selected={selected}
            setSelected={setSeleccted}
            placeholder={t("select_project")}
            isMulti={false}
            options={projects.map((p) => ({
              label: t(p.name),
              value: p.idProject,
            }))}
          />
        </Box>
      </Grid>
      <Grid item lg={12} display={"flex"} justifyContent={"center"} gap={2}>
        <Button onClick={handleSave} loading={isLoading}>
          {t("save")}
        </Button>
        <Button variant="outlined" onClick={close}>
          {t("cancel")}
        </Button>
      </Grid>
    </Grid>
  );
};
