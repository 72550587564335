import React, { useRef } from "react";
import { Box } from "@mui/material";

import { faFilterCircleXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface FilterChipsContainerProps {
  resetAll: () => void;
  children: React.ReactNode;
}

export const FilterChipsContainer = ({
  resetAll,
  children,
}: FilterChipsContainerProps) => {
  const chipsWrapperRef = useRef<any>(null);
  const innerElements = chipsWrapperRef?.current?.innerHTML;
  const count = (innerElements?.match(/<div/g) || []).length;

  return (
    <Box display={"flex"} columnGap={2} pt={2} alignItems={"center"}>
      {count > 0 && (
        <Box>
          <FontAwesomeIcon
            icon={faFilterCircleXmark}
            onClick={resetAll}
            style={{ cursor: "pointer" }}
          />
        </Box>
      )}
      <Box display={"flex"} columnGap={2} ref={chipsWrapperRef}>
        {children}
      </Box>
    </Box>
  );
};
