import { Card, CardContent } from "@mui/material";
import { FC } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import "./ProjectsAreaChart.scss";
const data = [
  {
    name: "05/2024",
    uv: 5,
    pv: 2,
    amt: 6,
  },
  {
    name: "06/2024",
    uv: 6,
    pv: 4,
    amt: 3,
  },
  {
    name: "07/2024",
    uv: 4,
    pv: 6,
    amt: 10,
  },
  {
    name: "08/2024",
    uv: 7,
    pv: 3,
    amt: 21,
  },
  {
    name: "09/2024",
    uv: 14,
    pv: 3,
    amt: 2,
  },
  {
    name: "10/2024",
    uv: 18,
    pv: 2,
    amt: 19,
  },
  {
    name: "11/2024",
    uv: 3,
    pv: 5,
    amt: 2,
  },
];
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

interface IProject {
  name: string;
  value: number;
}
interface IProjectsPieChartProps {
  projects: IProject[];
}

const ProjectsAreaChart: FC<IProjectsPieChartProps> = ({ projects }) => {
  return (
    <Card className="projectsAreaChart">
      <CardContent className="cardContent" sx={{ flex: 1 }}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="uv"
              stackId="1"
              stroke="#8884d8"
              fill="#8884d837"
            />
            <Area
              type="monotone"
              dataKey="pv"
              stackId="1"
              stroke="#82ca9d"
              fill="#82ca9e28"
            />
            <Area
              type="monotone"
              dataKey="amt"
              stackId="1"
              stroke="#ffc658"
              fill="#ffc75879"
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default ProjectsAreaChart;
