import _ from "lodash";
import { Speaker, User } from "@sumit-platforms/types";
import { useEffect, useState } from "react";
import speakerService from "../services/speakerService";
import { clientStore } from "../store/client";

const useSpeakers = ({ user }: { user: User | null }) => {
  const [speakers, setSpeakers] = useState<Speaker[]>([]);
  const { client } = clientStore();

  useEffect(() => {
    if (!user) return;
    getUserClientsSpeakers();
  }, [user, client]);

  const getUserClientsSpeakers = async () => {
    const clientSpeakers = await speakerService.getUserClientsSpeakers(
      user!.idUser,
      client?.idClient ? [client?.idClient] : undefined
    );

    const filteredSpeakers = clientSpeakers.filter(
      (s: Speaker) => !_.isEmpty(s.name)
    );

    const orderedSpeakers = _.orderBy(filteredSpeakers, ["name"]);

    setSpeakers(orderedSpeakers);
  };

  return { speakers };
};

export default useSpeakers;
