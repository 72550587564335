import _ from "lodash";
import { format } from "date-fns";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Button,
  DateFilter,
  ListFilter,
  Option,
  PopoverAction,
  SliderFilter,
} from "@sumit-platforms/ui-bazar";
import { ExtractFiltersChips } from "./ExtractFilterChips";
import { secondsToTimeString } from "@sumit-platforms/ui-bazar/utils";

export interface ExtractFiltersProps {
  filters: {
    [key: string]: {
      name: string;
      type: "list" | "date" | "range";
      options?: Option[];
      max?: number;
    };
  };
  setFilters: (filterValues: any) => void;
}

export const ExtractFilters = ({
  filters,
  setFilters,
}: ExtractFiltersProps) => {
  const { t } = useTranslation();

  const [filterValues, _setFilterValues] = useState<any>({});
  const setFilterValue = (filter: string, value: any) => {
    _setFilterValues((prevValues: any) => {
      if (_.isNil(value)) {
        return {
          ..._.omit(prevValues, filter),
        };
      }
      return {
        ...prevValues,
        [filter]: {
          type: filters[filter].type,
          value,
        },
      };
    });
  };
  useEffect(() => {
    setFilters(filterValues);
  }, [filterValues]);

  return (
    <Grid
      container
      display={"flex"}
      py={2}
      columnGap={2}
      alignItems={"center"}
      px={4}
    >
      <Grid item>
        <span>{t("filters")}:</span>
      </Grid>
      {_.map(filters, (filter, filterKey) => {
        switch (filter.type) {
          case "date":
            return (
              <Grid item key={filterKey}>
                <PopoverAction
                  trigger={
                    <Button variant="outlined" color={"primary"}>
                      {filter.name}
                    </Button>
                  }
                  renderContent={() => (
                    <Box
                      display={"flex"}
                      px={4}
                      pt={3}
                      gap={5}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <DateFilter
                        value={
                          _.get(filterValues, `[${filterKey}].value[0]`) as Date
                        }
                        setValue={(value) =>
                          setFilterValue(filterKey, [
                            value,
                            _.get(filterValues, `[${filterKey}].value[1]`),
                          ])
                        }
                        title={t("set_uploaded_start_date")}
                        position="start"
                      />
                      <DateFilter
                        value={
                          _.get(filterValues, `[${filterKey}].value[1]`) as Date
                        }
                        setValue={(value) =>
                          setFilterValue(filterKey, [
                            _.get(filterValues, `[${filterKey}].value[0]`),
                            value,
                          ])
                        }
                        title={t("set_uploaded_end_date")}
                        position="end"
                      />
                    </Box>
                  )}
                />
              </Grid>
            );
          case "list":
            return (
              <Grid item key={filterKey}>
                <PopoverAction
                  trigger={
                    <Button variant="outlined" color={"primary"}>
                      {`${filter.name} ${
                        filterValues[filterKey]?.value.length > 0
                          ? `(${filterValues[filterKey].value.length})`
                          : ""
                      } `}
                    </Button>
                  }
                  renderContent={() => (
                    <Box pt={2} px={2} onClick={(e) => e.stopPropagation()}>
                      <ListFilter
                        options={filter.options || []}
                        selected={filterValues[filterKey]?.value}
                        setSelected={(value) =>
                          setFilterValue(filterKey, value)
                        }
                      />
                    </Box>
                  )}
                />
              </Grid>
            );
          case "range":
            return (
              <Grid item key={filterKey}>
                <PopoverAction
                  trigger={
                    <Button variant="outlined" color={"primary"}>
                      {filter.name}
                    </Button>
                  }
                  renderContent={() => (
                    <Box py={2} px={2} onClick={(e) => e.stopPropagation()}>
                      <SliderFilter
                        min={0}
                        max={filter.max}
                        value={filterValues[filterKey].value || [0, filter.max]}
                        setValue={(value) => setFilterValue(filterKey, value)}
                        title={filter.name}
                        format={secondsToTimeString}
                      />
                    </Box>
                  )}
                />
              </Grid>
            );
        }
      })}

      <Grid item xs={12} px={2}>
        <ExtractFiltersChips
          filterValues={filterValues}
          setFilterValue={setFilterValue}
          resetFilters={() => _setFilterValues({})}
        />
      </Grid>
    </Grid>
  );
};
