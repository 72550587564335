import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import { FC } from "react";

interface IWelcomeButtonProps {
  icon: IconProp;
  mainText: string;
  secondaryText: string;
  onClick: () => void;
}

const WelcomeButton: FC<IWelcomeButtonProps> = ({
  icon,
  mainText,
  secondaryText,
  onClick,
}) => (
  <button
    onClick={onClick}
    style={{
      background: "rgba(232,235,240,1)",
      display: "flex",
      alignItems: "center",
      gap: 12,
      padding: "12px 24px",
      borderRadius: 8,
      flex: 1,
      border: "none",
      cursor: "pointer",
    }}
  >
    <FontAwesomeIcon style={{ color: "rgba(144,152,162,1)" }} icon={icon} />
    <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
      <Typography variant="body2" color="rgba(144,152,162,1)" textAlign="left">
        {mainText}
      </Typography>
      <Typography variant="body2" color="rgba(171,174,183,1)" textAlign="left">
        {secondaryText}
      </Typography>
    </div>
  </button>
);

export default WelcomeButton;
