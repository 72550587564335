import { Card, CardContent, MenuItem, Select, Typography } from "@mui/material";
import { FC } from "react";
import "./TranscriptionHours.scss";
import { SelectSimple, SelectSimpleOption } from "@sumit-platforms/ui-bazar";
interface ITranscriptionHoursProps {
  value?: string;
  projects: SelectSimpleOption[];
  onChange: (project: SelectSimpleOption) => void;
}
const TranscriptionHours: FC<ITranscriptionHoursProps> = ({
  value,
  projects,
  onChange,
}) => (
  <Card sx={{ flex: 1 }}>
    <CardContent>
      <div className="transcriptionHoursRoot">
        <Typography className="noShrink">Transcription hours by:</Typography>
        <SelectSimple options={projects} value={value} onChange={onChange} />
      </div>
    </CardContent>
  </Card>
);

export default TranscriptionHours;
