import { Card, CardContent } from "@mui/material";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import "./ProjectsPieChart.scss";
import { FC } from "react";
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

interface IProject {
  name: string;
  value: number;
}
interface IProjectsPieChartProps {
  projects: IProject[];
}

const ProjectsPieChart: FC<IProjectsPieChartProps> = ({ projects }) => {
  const sumHours = projects.reduce(
    (acc: number, curr: IProject) => acc + curr.value,
    0
  );

  return (
    <Card className="projectsPieChart">
      <CardContent className="cardContent" sx={{ flex: 1 }}>
        <div className="legend">
          {projects.map((project: IProject, index: number) => (
            <div key={index} className="legendItem">
              <div className="legendTextWrapper">
                <div
                  className="legendColor"
                  style={{ backgroundColor: COLORS[index % COLORS.length] }}
                />

                <div className="legendText">{project.name}:</div>
              </div>
              <div className="legendHours">
                {project.value} hours (
                {Math.round((project.value / sumHours) * 100)}
                %)
              </div>
            </div>
          ))}
        </div>
        <div className="pie">
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={projects}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
              >
                {projects.map((_: IProject, index: number) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

export default ProjectsPieChart;
