import React, { useCallback, useEffect, useState } from "react";
import { useSlateStatic } from "@sumit-platforms/slate-react";
import { useRecoilValue } from "recoil";
import { currentTimeState } from "../../store/states";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-thin-svg-icons";
import { useTranslation } from "react-i18next";
import EditorService from "../../services/EditorService";
import { CustomEditor, SubtitleRangeElement } from "../../types";
import _ from "lodash";

import "./LiveTimeSetter.scss";
import { getTimecodeFromSeconds } from "@sumit-platforms/ui-bazar/utils";
import MediaService from "../../services/MediaService";

export const LiveTimeSetter = ({
  position,
  disabled,
}: {
  position?: "st" | "et";
  disabled?: boolean;
}) => {
  const editor = useSlateStatic() as CustomEditor;
  const { t } = useTranslation();
  const currentTime = useRecoilValue(currentTimeState);

  const handleClick = useCallback(() => {
    if (!_.isNumber(editor.startIndex) || disabled) return;
    const index = editor.startIndex + 1;
    const element = editor.children[index] as SubtitleRangeElement;
    if (!element || element.type !== "subtitleRange" || !position) return;
    EditorService.updateNodeData({
      data: {
        range: {
          ...element.range,
          [position]: currentTime,
          time_edit: true,
        },
      },
      element,
      editor,
    });
    EditorService.updateElementRange({
      editor,
      entry: {
        element: editor.children[index] as SubtitleRangeElement,
        path: index,
      },
    });
    EditorService.createWaveformRanges(editor, false);
  }, [currentTime, disabled, editor, position]);

  return currentTime ? (
    <div
      className={classNames("LiveTimeSetter", { disabled })}
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faClock} />
      <span>
        {t("set")}{" "}
        {getTimecodeFromSeconds(currentTime, {
          fps: MediaService.frameRate,
          tcOffsets: MediaService.tcOffsets,
        })}
      </span>
    </div>
  ) : null;
};
