import axios from "axios";
import config from "../config";
import { Tag } from "@sumit-platforms/types";

const endpoint = `${config.server.host}/${config.server.speakers}`;

const getUserClientsSpeakers = async (
  idUser: number,
  idClient?: number[]
): Promise<any> => {
  try {
    const response = await axios.get(endpoint, {
      params: {
        idUser,
        idClient,
      },
    });
    if (response.status !== 200) throw new Error(response.statusText);
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export default { getUserClientsSpeakers };
