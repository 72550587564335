import { create } from "zustand";

// NOTE: in order to use this navigation blocker we must set an open confirm modal function.
// the function will set in the relevant component with the relevant context
type BlockNavModal = ({ to }: { to: string }) => void;

export type NavigationModalBlocker = {
  blockNavModal?: BlockNavModal;
  setBlockNavModal: (fn: BlockNavModal | undefined) => void;
  clearBlockNavModal: () => void;
};

export const useNavigationBlocker = create<NavigationModalBlocker>((set) => {
  return {
    blockNavModal: undefined,
    setBlockNavModal: (openConfirmModal: BlockNavModal | undefined) => {
      set((state: NavigationModalBlocker) => {
        return { ...state, blockNavModal: openConfirmModal };
      });
    },
    clearBlockNavModal: () => {
      set((state: NavigationModalBlocker) => {
        return { ...state, blockNavModal: undefined };
      });
    },
  };
});
