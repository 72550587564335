import config from "../config";
import { create } from "zustand";
import jobService from "../services/jobService";
import { Project, Toast } from "@sumit-platforms/types";
import { ProjectService } from "@sumit-platforms/ui-bazar/services";

const projectService = ProjectService({ config });

export type GlobalData = {
  jobTypes: any[];
  setJobTypes: (jobTypes: any[]) => void;
  fetchGlobalData: any;
  toast: Toast | null;
  setToast: (toast: Toast | null) => void;
  projects: Project[];
  setProjects: (projects: Project[]) => void;
};

export const useGlobalData = create<GlobalData>((set) => ({
  jobTypes: [],
  setJobTypes: (jobTypes: any[]) => {
    set((state: GlobalData) => {
      return { ...state, jobTypes };
    });
  },
  fetchGlobalData: async ({ idClient }: { idClient: number }) => {
    console.log("fetching global data...");
    try {
      const jobTypes = await jobService.getJobTypes();
      const projects = await projectService.getClientProjects(idClient);
      set((state) => ({ ...state, jobTypes, projects }));
    } catch (err) {
      console.error(err);
    }
  },
  toast: null,
  setToast: (toast: Toast | null) => {
    set((state: GlobalData) => {
      return { ...state, toast };
    });
  },
  projects: [],
  setProjects: (projects: Project[]) => {
    set((state: GlobalData) => {
      return { ...state, projects };
    });
  },
}));
