import { User } from "@sumit-platforms/types";
import { create } from "zustand";

export type UserStore = {
  user: User | null;
  setUser: (user: User | null) => void;
  lastPageVisited: string;
  setLastPageVisited: (lastPageVisited: string) => void;
};

export const useUser = create<UserStore>((set) => ({
  user: null,
  lastPageVisited: "/",
  setUser: (user: User | null) => {
    set((state: UserStore) => {
      return { ...state, user };
    });
  },
  setLastPageVisited(lastPageVisited: string) {
    set((state: UserStore) => {
      return { ...state, lastPageVisited };
    });
  },
}));
