import { Client } from "@sumit-platforms/types";
import { LocalStorageService } from "@sumit-platforms/ui-bazar/services";
import { create } from "zustand";

export type ClientStore = {
  client: Client | null;
  setClient: (client: Client | null) => void;
};

export const clientStore = create<ClientStore>((set) => ({
  client: LocalStorageService.getItem<Client>("client") || null,
  setClient: (client: Client | null) => {
    set((state: ClientStore) => {
      LocalStorageService.setItem("client", client);
      return { ...state, client };
    });
  },
}));
