import { useEffect } from "react";
import { useIdToken } from "react-firebase-hooks/auth";
import authService from "../services/authService";
import { User } from "@sumit-platforms/types";
import { useUser } from "../store/user";
import { LocalStorageService } from "@sumit-platforms/ui-bazar/services";
import * as Sentry from "@sentry/react";

const useAuth = (): {
  authUser: any;
  userLoading: boolean;
  userError: Error | undefined;
} => {
  const [authUser, userLoading, userError] = useIdToken(authService.auth);
  const { setUser } = useUser();

  useEffect(() => {
    if (authUser) {
      authService
        .getUser()
        .then((userDetails: any) => {
          let loggedInUser = null;
          if (userDetails) {
            loggedInUser = {
              ...userDetails,
              firebase: authUser,
              loading: userLoading,
              error: userError,
            };
          }
          setUser(loggedInUser as User);
        })
        .catch(async (err: any) => {
          console.error(err);
          setUser(null);
          LocalStorageService.clearAll();
          await authService.logout();
        });
      Sentry.setUser({
        uid: authUser.uid,
        name: authUser.displayName,
        email: authUser.email || "",
      });
    }
  }, [authUser]);

  return {
    authUser,
    userLoading,
    userError,
  };
};

export default useAuth;
