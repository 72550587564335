import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { faFolderOpen, faSearch } from "@fortawesome/pro-light-svg-icons";
import WelcomeButton from "./WelcomeButton";
interface IWelcomeProps {
  userName: string;
}

const Welcome: FC<IWelcomeProps> = ({ userName }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onBrowseMyDeliveries = () => navigate("/deliveries");
  const onSearchMyContent = () => navigate("/deliveries");
  return (
    <Card sx={{ flex: 1 }}>
      <CardContent>
        <Typography variant="h6">{t("welcome_user", { userName })}</Typography>
        <Typography variant="body2" color="text.secondary">
          Whare are we doing today?
        </Typography>
      </CardContent>
      <div
        style={{
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          gap: 16,
        }}
      >
        <WelcomeButton
          icon={faFolderOpen}
          mainText={"Browse my deliveries"}
          secondaryText="Explore and download my deliveries"
          onClick={onBrowseMyDeliveries}
        />
        <WelcomeButton
          icon={faSearch}
          mainText={"Search my content"}
          secondaryText="Search for moments and information"
          onClick={onSearchMyContent}
        />
      </div>
    </Card>
  );
};

export default Welcome;
