import { Card, CardContent } from "@mui/material";
import ProjectsAreaChart from "./components/ProjectsAreaChart/ProjectsAreaChart";
import ProjectsPieChart from "./components/ProjectsPieChart/ProjectsPieChart";
import Stats from "./components/Stats/Stats";
import TranscriptionHours from "./components/TranscriptionHours/TranscriptionHours";
import Welcome from "./components/Welcome/Welcome";
import "./Home.scss";

const projects = [
  { value: "1", label: "Project 1" },
  { value: "2", label: "Project 2" },
];

const stats = {
  files: {
    total: 100,
    lastMonth: 10,
  },
  minutes: {
    total: 100,
    lastMonth: 10,
  },
  searches: {
    total: 100,
    lastMonth: 10,
  },
};

const projectsStats = [
  {
    name: "Project 1",
    value: 16.5,
  },
  {
    name: "Project 2",
    value: 31.5,
  },
  {
    name: "Project 3",
    value: 52,
  },
  {
    name: "Project 4",
    value: 50,
  },
];

const Home = () => (
  <div className="Home">
    <div className="firstRow">
      <div className="welcomeWrapper">
        <Welcome userName="Jasmin Butbul" />
      </div>
      <div className="statsWrapper">
        <Stats
          files={stats.files}
          minutes={stats.minutes}
          searches={stats.searches}
        />
      </div>
      <div className="talkWithUsWrapper">
        <Card sx={{ flex: 1 }}>
          <CardContent></CardContent>
        </Card>
      </div>
    </div>
    <div className="secondRow">
      <div className="column">
        <div className="transcriptionHoursWrapper">
          <TranscriptionHours
            projects={projects}
            onChange={(v) => console.log(v)}
          />
        </div>
        <div className="projectsPieChartWrapper">
          <ProjectsPieChart projects={projectsStats} />
        </div>
        <div className="areaChartWrapper">
          <ProjectsAreaChart projects={projectsStats} />
        </div>
      </div>
      <div className="column">
        <Card sx={{ flex: 1 }}>
          <CardContent></CardContent>
        </Card>
      </div>
    </div>
  </div>
);

export default Home;
