import classNames from "classnames";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import React, { useMemo, useState } from "react";
import { Button, Input } from "@sumit-platforms/ui-bazar";
import { Grid } from "@mui/material";

import "./ExtractExportModal.scss";

export interface ExtractExportModalProps {
  confirm: (fileName: string) => void;
  cancel: () => void;
}

export const ExtractExportModal = ({
  confirm,
  cancel,
}: ExtractExportModalProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [fileName, setFileName] = useState<string>("");
  const isDisabled = useMemo(() => fileName === "", [fileName]);

  const handleConfirm = async () => {
    if (isDisabled) return;
    try {
      setIsLoading(true);
      await confirm(fileName);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      throw new Error(err);
    }
  };

  return (
    <Grid
      className={"ExtractExportModal"}
      container
      justifyContent={"center"}
      py={3}
      px={4}
    >
      <Grid
        item
        xs={12}
        flexDirection={"column"}
        display={"flex"}
        justifyContent={"center"}
        pb={3}
        gap={1}
      >
        <label>{t("file_name")}:</label>
        <Input
          type="text"
          value={fileName}
          onChange={(e) => setFileName(e.target?.value || "")}
          placeholder={t("file_name")}
        />
      </Grid>
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        pb={2}
        gap={2}
      >
        <Button onClick={cancel} variant="outlined">
          {t("cancel")}
        </Button>
        <Button
          loading={isLoading}
          disabled={isDisabled}
          onClick={handleConfirm}
          color="primary"
        >
          {t("export")}
        </Button>
      </Grid>
    </Grid>
  );
};
