import _ from "lodash";
import { format } from "date-fns";
import React from "react";
import { JobTypes } from "@sumit-platforms/types";
import { Chip, ElementIf } from "@sumit-platforms/ui-bazar";
import { FilterChipsContainer } from "../../../components/FilterChipsContainer/FilterChipsContainer";

import { FILTER_DATE_FORMAT_NO_HOURS } from "../../../constants/globals";

export interface ExtractFiltersChipsProps {
  filterValues: any;
  setFilterValue: (filter: string, value: any) => void;
  resetFilters: () => void;
}

export const ExtractFiltersChips = ({
  filterValues,
  setFilterValue,
  resetFilters,
}: ExtractFiltersChipsProps) => {
  return (
    <FilterChipsContainer resetAll={resetFilters}>
      {/* Uploaded */}
      {filterValues.created_at?.value ? (
        <Chip
          label={`${format(
            filterValues.created_at?.value[0] || Date.now(),
            FILTER_DATE_FORMAT_NO_HOURS
          )} - ${format(
            filterValues.created_at?.value[1] || Date.now(),
            FILTER_DATE_FORMAT_NO_HOURS
          )}`}
          action={() => {
            setFilterValue("created_at", null);
            setFilterValue("created_at", null);
          }}
        />
      ) : (
        ""
      )}
      {/* JobType */}
      {filterValues.job_type?.value ? (
        <Chip
          label={filterValues.job_type.value.slice(0, 2).join(", ")}
          action={() => setFilterValue("job_type", null)}
          moreSuffix={filterValues.job_type.value.length > 2}
        />
      ) : (
        ""
      )}
      {/* Tags */}
      {filterValues.tags?.value ? (
        <Chip
          label={filterValues.tags.value.slice(0, 2).join(", ")}
          action={() => setFilterValue("tags", null)}
          moreSuffix={filterValues.tags.value.length > 2}
        />
      ) : (
        ""
      )}
      {/* Speakers */}
      {filterValues.speaker?.value ? (
        <Chip
          label={filterValues.speaker.value.slice(0, 2).join(", ")}
          action={() => setFilterValue("speaker", null)}
          moreSuffix={filterValues.speaker.value.length > 2}
        />
      ) : (
        ""
      )}
      {/* <ElementIf
        elementIf={
          format(uploadedStart, FILTER_DATE_FORMAT) !==
            format(FILTER_INITIAL_DATE, FILTER_DATE_FORMAT) ||
          format(uploadedEnd, FILTER_DATE_FORMAT) !==
            format(FILTER_INITIAL_DATE, FILTER_DATE_FORMAT)
        }
        element={
          <Chip
            label={`${format(uploadedStart, FILTER_DATE_FORMAT)} - ${format(
              uploadedEnd,
              FILTER_DATE_FORMAT
            )}`}
            action={() => {
              setUploadedStart(FILTER_INITIAL_DATE);
              setUploadedEnd(FILTER_INITIAL_DATE);
            }}
          />
        }
      /> */}
      {/* <ElementIf
        elementIf={sourceLang.length > 0 || targetLang.length > 0}
        element={
          <Chip
            label={`${sourceLang[0] ? sourceLang[0] : ""} ${
              targetLang[0] ? "- " + targetLang[0] : ""
            }`}
            action={() => {
              setSourceLang([]);
              setTargetLang([]);
            }}
          />
        }
      /> */}
      {/* <ElementIf
        elementIf={duration[0] !== 0 || duration[1] !== FILTER_MAX_DURATION}
        element={
          <Chip
            label={`${secondsToTimeString(duration[0])} - ${secondsToTimeString(
              duration[1]
            )}`}
            action={() => setDuration([0, FILTER_MAX_DURATION])}
          />
        }
      /> */}
    </FilterChipsContainer>
  );
};
