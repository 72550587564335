import React from "react";
import {
  Avatar,
  Box,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import { defaultAvatar } from "@sumit-platforms/common-assets/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import classNames from "classnames";
import { bazarTheme } from "../../bazar-theme";

import "./ContextSelect.scss";

interface ContextOption {
  id: any;
  label: string;
  value: any;
  image?: string;
}

export interface ContextSelectProps {
  options: ContextOption[];
  onChange: (value: any) => void;
  value: any;
  isOpen?: boolean;
}

export const ContextSelect = ({
  options,
  onChange,
  value,
  isOpen = true,
}: ContextSelectProps) => {
  const handleOnChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  const arrowStyle = {
    position: "absolute !important",
    right: "0 !important",
    pointerEvents: "none !important",
  };

  return (
    <ThemeProvider theme={bazarTheme}>
      <Box className={classNames("ContextSelect", { closed: !isOpen })}>
        <Select
          onChange={handleOnChange}
          value={value}
          className="selectContextSelect"
          IconComponent={() =>
            isOpen ? (
              <Box px={2} fontStyle={arrowStyle}>
                <FontAwesomeIcon icon={faChevronRight} />
              </Box>
            ) : (
              <></>
            )
          }
          disableUnderline={true}
          variant="standard"
          MenuProps={{
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        >
          {options.map((o) => {
            return (
              <MenuItem
                key={o.id}
                value={o.value}
                className="menuItemContextSelect"
              >
                <Tooltip
                  title={!isOpen ? o.label : ""}
                  arrow
                  placement="right"
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, 15],
                        },
                      },
                    ],
                  }}
                >
                  <ListItemButton>
                    <ListItemAvatar>
                      <Avatar src={o.image || defaultAvatar} />
                    </ListItemAvatar>
                    <ListItemText primary={o.label} />
                  </ListItemButton>
                </Tooltip>
              </MenuItem>
            );
          })}
        </Select>
      </Box>
    </ThemeProvider>
  );
};
