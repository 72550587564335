import _ from "lodash";
import { Box, Grid } from "@mui/material";
import {
  Button,
  Input,
  TimecodePicker,
  TimerPicker,
} from "@sumit-platforms/ui-bazar";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Job } from "@sumit-platforms/types";
import {
  getSecondsFromTimecode,
  getTimeNumberFromString,
  getTimecodeFromSeconds,
} from "@sumit-platforms/ui-bazar/utils";
import { useFeatureFlag } from "@sumit-platforms/ui-bazar/hooks";

import "./EditJobModal.scss";

export interface EditJobModalProps {
  job: Job;
  cancel: () => void;
  save: (job: Job) => Promise<void>;
}

export const EditJobModal = ({ job, cancel, save }: EditJobModalProps) => {
  const { t } = useTranslation();
  const timecodePickerFF = useFeatureFlag("timecodePicker");

  const [jobFields] = useState([
    {
      key: "name",
      label: t("name"),
      type: "text",
    },
    {
      key: "tcOffsets",
      label: t("tc_offset"),
      type: "timecode",
      formatter: (value: number[][] | null) =>
        getTimecodeFromSeconds(0, { tcOffsets: value }),
      updateFormatter: (value: number) => [[0, value]],
    },
  ]);
  const [updatedJob, setUpdatedJob] = useState(
    _.pick(
      job,
      jobFields.map((f) => f.key)
    )
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    setIsLoading(true);
    await save(updatedJob as Job);
    setIsLoading(false);
    cancel();
  };

  const handleChange = async (field: string, value: any) => {
    setUpdatedJob((prevJob) => {
      return {
        ...prevJob,
        [field]: value,
      };
    });
  };

  return (
    <Grid
      className={"EditJobModal"}
      container
      maxWidth={420}
      justifyContent={"center"}
      px={4}
      py={3}
    >
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        gap={2}
        pb={3}
      >
        <span className="modalName">{t("edit_job")}</span>
      </Grid>
      <Grid item pb={4} xs={12}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
          rowGap={2}
        >
          {jobFields.map((field) => (
            <Grid item width={"100%"} className={field.key} key={field.key}>
              {/* String */}
              {field.type === "text" && (
                <div>
                  <span>{field.label}</span>
                  <Input
                    value={
                      field.formatter
                        ? field.formatter(_.get(updatedJob, field.key))
                        : _.get(updatedJob, field.key)
                    }
                    onChange={(e) =>
                      handleChange(field.key, e.target?.value || "")
                    }
                  />
                </div>
              )}
              {/* Timer */}
              {field.type === "timecode" && (
                <div>
                  <span>{field.label}</span>
                  {timecodePickerFF ? (
                    <TimecodePicker
                      value={
                        field.formatter
                          ? field.formatter(_.get(updatedJob, field.key))
                          : _.get(updatedJob, field.key)
                      }
                      handleBlur={_.noop}
                      handleChange={(value) => {
                        return handleChange(
                          field.key,
                          field.updateFormatter
                            ? field.updateFormatter(
                                getSecondsFromTimecode(value)
                              )
                            : getSecondsFromTimecode(value)
                        );
                      }}
                    />
                  ) : (
                    <TimerPicker
                      value={
                        field.formatter
                          ? field.formatter(_.get(updatedJob, field.key))
                          : _.get(updatedJob, field.key)
                      }
                      step={1}
                      handleBlur={_.noop}
                      handleChange={(value) => {
                        return handleChange(
                          field.key,
                          field.updateFormatter
                            ? field.updateFormatter(
                                getTimeNumberFromString(value)
                              )
                            : getTimeNumberFromString(value)
                        );
                      }}
                      showMillis={false}
                    />
                  )}
                </div>
              )}
            </Grid>
          ))}
        </Box>
      </Grid>
      <Grid item lg={12} display={"flex"} justifyContent={"center"} gap={2}>
        <Button onClick={handleSave} loading={isLoading}>
          {t("save")}
        </Button>
        <Button variant="outlined" onClick={cancel}>
          {t("cancel")}
        </Button>
      </Grid>
    </Grid>
  );
};
